import React from "react"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { FadeInWrapper } from "../components/FadeInWrapper"
import { CTABlock } from "../components/CTABlock"
import social3 from "../assets/images/social-proof-3.jpg"
import social4 from "../assets/images/social-proof-4.jpg"
import social1 from "../assets/images/social-proof-1.png"
import yaldaHarley from "../assets/images/yalda-harley.jpg"
import yaldaBottle from "../assets/images/yalda-bottle.jpg"
import yalda from "../assets/images/yalda.png"
import shima from "../assets/images/shima.png"
import tas from "../assets/images/tas.png"
import helpers from "../styles/helpers.module.css"

const About = () => {
  return (
    <Layout page="About">
      <SEO title="About" />
      <FadeInWrapper>
        <div>
          <div className="container px-6 py-32 mx-auto sm:px-8 md:px-12 lg:px-6 md:py-40 lg:py-48">
            <div className="xl:px-4">
              <div className="grid gap-2 lg:grid-cols-12">
                <div className="lg:col-span-10 lg:col-start-2">
                  <p className={`${helpers.overline} mb-2`}>About</p>
                  <h1 className="text-4xl leading-tight text-left md:px-0 md:text-5xl">
                    Dr Yalda is a qualified medical doctor specialising in
                    aesthetic medicine. She graduated from the University of
                    Manchester with a Bachelor of Medicine, Bachelor of Surgery
                    (MBChB) and holds a Level 7 postgraduate qualification in
                    Aesthetic Medicine.
                  </h1>
                  <div className="flex items-center mt-6">
                    <img className="w-10 h-10 mr-3" src={social1} alt="" />
                    <img className="w-10 h-10 mr-3" src={social3} alt="" />
                    <img className="w-12 h-12 mr-4" src={social4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <div className="px-6 bg-gray-100 sm:px-8 md:px-12 lg:px-0">
          <div className="container grid grid-cols-1 gap-2 py-16 mx-auto md:b-32">
            <div className="xl:px-4">
              <div className="grid gap-2 lg:grid-cols-12">
                <div className="lg:col-span-10 lg:col-start-2">
                  <div>
                    <img
                      className="shadow-md"
                      src={yaldaHarley}
                      alt="Dr Yalda in a blue dress, sitting on a blue sofa, being filmed at the CCR Expo conference"
                    />
                    <p className={`${helpers.caption} mt-4`}>
                      Dr Yalda at CCR Expo conference as part of the Harley
                      Academy Faculty
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <div className="bg-gray-100 sm:px-8 md:px-12 lg:px-0">
        <div className="container grid grid-cols-1 gap-2 px-6 pb-16 mx-auto md:pb-32">
          <div className="xl:px-4">
            <div className="grid gap-2 lg:grid-cols-12">
              <div className="lg:col-span-10 lg:col-start-2">
                <p className="mb-6 font-serif text-2xl text-gray-900 xl:tracking-wider xl:leading-relaxed xl:text-3xl">
                  Dr Yalda&apos;s journey into Aesthetic Medicine started in
                  Nottingham, where she set up her own aesthetic clinic
                  alongside working as an NHS doctor. As her cosmetic career
                  progressed, she began to work as a Lead Trainer for{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.harleyacademy.com/"
                    className="serifLink"
                  >
                    Harley Academy
                  </a>
                  , UK&apos;s largest training provider in Aesthetic Medicine.
                  Dr Yalda currently works in Nottingham, London and Bicester.
                  She has a key artistic eye for detail, and her clients regard
                  her as a personable, results-oriented aesthetics doctor. Dr
                  Yalda goes above and beyond to ensure that her clients are
                  completely satisfied by their results.
                </p>
                <p className="mb-6 font-serif text-2xl text-gray-900 xl:tracking-wider xl:leading-relaxed xl:text-3xl">
                  Having studied and lived in the North West, Dr Yalda has
                  expanded her clinic to Manchester and Leeds.
                </p>
                <p className="mb-6 font-serif text-2xl text-gray-900 xl:tracking-wider xl:leading-relaxed xl:text-3xl">
                  She has carefully selected her team of medically qualified
                  practitioners which consists of Doctors and Pharmacists. With
                  her training experience, Dr Yalda has taught her techniques
                  and core principles to her team of medically qualified
                  practitioners. She provides ongoing teaching and supervision
                  to continually expand and improve techniques in a fast-paced
                  and changing industry. Her insight, expert supervision and the
                  use of premium brands ensure gold-standard results are reached
                  every time.
                </p>
                <p className="mb-6 font-serif text-2xl text-gray-900 xl:tracking-wider xl:leading-relaxed xl:text-3xl">
                  ⁣⁣In addition to running her Clinics, Dr Yalda works with{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ayadharb.co.uk/"
                    className="serifLink"
                  >
                    Dr Ayab Harb
                  </a>{" "}
                  at{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.omniya.co.uk/"
                    className="serifLink"
                  >
                    Omniya Clinic
                  </a>{" "}
                  in London and Bicester Clinic in Bicester.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white sm:px-8 md:px-12 lg:px-0">
        <div className="container grid grid-cols-1 gap-2 px-6 py-16 mx-auto md:py-32">
          <div className="xl:px-4">
            <div className="grid gap-2 lg:grid-cols-12">
              <div className="lg:col-span-10 lg:col-start-2">
                <div className="mt-8 mb-2">
                  <p className="mb-6 font-serif text-4xl leading-tight text-gray-900 lg:text-5xl">
                    &ldquo;I am passionate about ensuring aesthetics is a
                    carefully regulated industry, conducted with extreme
                    precision, diligence and care.&rdquo;
                  </p>
                  <p className="text-lg font-light text-gray-700">
                    – Dr Yalda Jamali
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 sm:px-8 md:px-12 lg:px-0">
        <div className="container grid grid-cols-1 gap-2 px-6 py-16 mx-auto md:py-32">
          <div className="xl:px-4">
            <div className="grid gap-2 lg:grid-cols-12">
              <div className="lg:col-span-10 lg:col-start-2">
                <div>
                  <img
                    className="shadow-md"
                    src={yaldaBottle}
                    alt="Dr Yalda holds up a bottle of product"
                  />
                  <p className={`${helpers.caption} mt-4`}>
                    Dr Yalda working in her Nottingham Clinic
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FadeInWrapper>
        <div className="container px-6 py-16 mx-auto lg:py-32 md:py-24 sm:px-8 md:px-12 lg:px-6 xl:px-16">
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-12 mb-12 text-center">
              <h2>Meet The Team</h2>
            </div>
            <div className="col-span-12 mb-16 text-center md:col-span-4">
              <div className="flex items-center justify-center w-32 h-32 mx-auto mb-4 overflow-hidden border-2 border-solid rounded-full border-lightAccent">
                <img src={yalda} />
              </div>
              <h3 className="">Dr Yalda Jamali</h3>
              <p className="mb-4 font-semibold tracking-wide uppercase">
                Clinic Founder
              </p>
              <p className="mb-4 text-center text-gray-700">
                Dr Yalda started her clinic in 2017 in Nottinghamshire. She has
                worked alongside the best leading cosmetic practitioner in the
                U.K. and has dedicated a large portion of her cosmetic career to
                teaching in the field. She currently is based in Sydney,
                Australia.
              </p>
              <p className="mb-4 text-center text-gray-700">
                <strong>
                  <span className="font-bold tracking-wide ">Location: </span>
                </strong>
                Nottingham, London and Bicester
              </p>
            </div>
            <div className="col-span-12 mb-16 text-center md:col-span-4">
              <div className="flex items-center justify-center w-32 h-32 mx-auto mb-4 overflow-hidden border-2 border-solid rounded-full border-lightAccent">
                <img src={shima} />
              </div>{" "}
              <h3 className="">Miss Shima Samivand</h3>
              <p className="mb-4 font-semibold tracking-wide uppercase">
                Clinical Prescribing Pharmacist
              </p>
              <p className="mb-4 text-center text-gray-700">
                Shima (MPharm) is a Clinical Pharmacist and a fully qualified
                Aesthetic Practitioner with Dr Yalda Clinics working in Leeds.
                Shima is particularly passionate about skin health both in a
                professional and personal capacity and an advocate for
                medical-grade skincare.{" "}
              </p>
              <p className="mb-4 text-center text-gray-700">
                Having been trained personally by Dr Yalda, Shima has applied
                her broad skillset from her GP setting to become a fantastic
                Aesthetic Practitioner.
              </p>
              <p className="mb-4 text-center text-gray-700">
                Shima prides herself on providing honest, personable advice and
                is a strong believer that subtle enhancements offer the best
                results. Her aesthetic experience in facial injectables include
                lips, facial contouring, anti-wrinkling and dermal fillers.
                Shima is training towards the Level 7 Postgraduate qualification
                in Aesthetic Medicine.
              </p>
              <p className="mb-4 text-center text-gray-700">
                <strong>
                  <span className="font-bold tracking-wide ">Location: </span>
                </strong>
                Leeds
              </p>
            </div>
            <div className="col-span-12 text-center md:col-span-4">
              <div className="flex items-center justify-center w-32 h-32 mx-auto mb-4 overflow-hidden border-2 border-solid rounded-full border-lightAccent">
                <img src={tas} />
              </div>{" "}
              <h3 className="">Dr Tasleema Begum</h3>
              <p className="mb-4 font-semibold tracking-wide uppercase">
                Aesthetic Doctor
              </p>
              <p className="mb-4 text-center text-gray-700">
                Dr Tasleema (MbChB Hons) is a medical doctor and a qualified
                Aesthetic practitioner with Dr Yalda clinics. She trained with
                Harley Academy and has been personally mentored by Dr Yalda in
                order to ensure the best treatment and techniques for all
                patients.{" "}
              </p>
              <p className="mb-4 text-center text-gray-700">
                She is currently based in Manchester and continues to work in
                the NHS alongside her aesthetic work at Dr Yalda Clinics.
              </p>
              <p className="mb-4 text-center text-gray-700">
                Her broad range of skills and medical knowledge allows her to
                assess patients fully and provide the best advice for a wide
                range of aesthetic and skin concerns. Dr Tasleema is passionate
                about providing safe and evidence-based treatments to her
                patients and is an advocate for subtle enhancements. She offers
                a variety of non-invasive facial injectable and skin resurfacing
                treatments. Dr Tasleema is constantly updating her knowledge and
                experience; she is currently working towards her level 7
                postgraduate qualification Aesthetic Medicine
              </p>
              <p className="mb-4 text-center text-gray-700">
                <strong>
                  <span className="font-bold tracking-wide ">Location: </span>
                </strong>
                Manchester
              </p>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <CTABlock />
      </FadeInWrapper>
    </Layout>
  )
}

export default About
