import React, { useState, useEffect, useRef, MutableRefObject } from "react"
import helpers from "../../styles/helpers.module.css"

export const FadeInWrapper = props => {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef() as MutableRefObject<HTMLInputElement>

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        setVisible(entry.isIntersecting)
      })
    })

    const { current } = domRef
    observer.observe(current)

    return () => observer.unobserve(current)
  }, [])
  return (
    <div
      className={`${helpers.fadeInSection} ${
        isVisible ? helpers.fadeInSectionVisible : ""
      }`}
      ref={domRef}
    >
      {props.children}
    </div>
  )
}
